<template>
  <div class="blogs-container">
    <div class="blogs-header">
      <div class="head">
        BLOGS
      </div>
      <div class="sub">
        เกร็ดความรู้
      </div>
    </div>
    <div class="blogs-body">
      <div v-for="(video, i) in videoSchema" :key="i" class="row mb-5">
        <div class="col-12 title h4 text-center bg-pink2 color-white1 p-3">
          {{ video.title }}
        </div>
        <div
          v-for="(url, l) in video.url"
          :key="l"
          class="col-12 col-md-6 my-2 my-md-0 p-3"
          :class="{
            'col-12 col-md-12 order-md-1 pb-5 text-center':
              videoPlay.col == l && videoPlay.row == i,
            'order-md-2': videoPlay.col != l || videoPlay.row != i
          }"
        >
          <div class="blogs-item">
            <div class="content">
              <youtube
                :video-id="url.url"
                class="w-100 h-100"
                player-width="100%"
                player-height="100%"
                @ready="ready"
                @playing="playing(l, i)"
                @pause="pause(l)"
              ></youtube>
            </div>
          </div>
          <div
            class="title mt-2 font-weight-bold mb-2"
            :class="{ h3: videoPlay.col == l && videoPlay.row == i }"
          >
            {{ url.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Blogs",
  metaInfo: {
    title: "เกร็ดความรู้",
    meta: [
      {
        name: "og:title",
        content: "เกร็ดความรู้ | Revealing Clinic"
      }
    ]
  },
  data() {
    return {
      videoPlay: {
        col: null,
        row: null
      },
      player: [],
      videoSchema: [
        {
          title: "ดูดไขมัน",
          url: [
            {
              url: "s2xm2VhjY24",
              title: "เอวเอสสร้างได้"
            },
            {
              url: "yRNWhRwzfro",
              title: "7 วันเอวเล็ก ไซซ์เปลี่ยน"
            },
            {
              url: "loag7OzwvYY",
              title: "อัพเดอทเอวเอส 1 เดือน หายไป 5 นิ้วน"
            },
            {
              url: "DUd-9P9wOeQ",
              title: "อัพเดอทเอวเอส 1 เดือน หายไป 5 นิ้วน"
            },
            {
              url: "Ja51WS6GkVk",
              title: "ดูดไขมันเห็นผลแค่ไหน❗"
            },
            {
              url: "kWqbs1iK0s0",
              title: "การกำหนดบริเวณดูดไขมัน"
            }
          ]
        },
        {
          title: "ดูดไขมันต้นแขน",
          url: [
            { url: "aVb3xFnlIyE", title: "ดูดไขมันกระชับแขน" },
            { url: "7J_C0MOj5lw", title: "ดูดไขมันต้นเเขน ให้เล็กเรียว" }
          ]
        },
        {
          title: "ดูดไขมันต้นขา",
          url: [
            { url: "lHfsFG02Lgc", title: "อัพเดท 7 วัน พร้อมวิธีดูแลตัวเอง" },
            {
              url: "V2bxdTLPxmI",
              title: "ขาใหญ่ไม่ใช่ปัญหา ดูดไขมันบอกลาต้าขาใหญ่"
            }
          ]
        },
        {
          title: "วิตามินผิว",
          url: [
            {
              url: "KlRTL6I3p2g",
              title: "✨ เติมวิตามินผิว เพิ่มความกระจ่างใส"
            },
            { url: "oPYN4g4f288", title: "รีวิววิตามินผิว Revealing Special" }
          ]
        },
        {
          title: "Filler & Botox",
          url: [
            {
              url: "VF593uXh9og",
              title: "ด้วย Filler Juvederm แท้ ปรับรูปให้ให้อวบอิ่ม ได้รูป"
            },
            {
              url: "xt_33o0nLLc",
              title: "BOTOX ปรับรูปหน้าให้เท่ากัน ลดริ้วรอย หน้าเรียว"
            }
          ]
        },
        {
          title: "เสริมจมูก",
          url: [
            {
              url: "8O32aqo4JY4",
              title: "ร้อยไหม หน้าเรียวทันใจ ร้อยปุ๊บ ยกปั๊บ"
            }
          ]
        },
        {
          title: "PRP",
          url: [
            { url: "wGXY2mcwR7g", title: "PRP สวยหน้าใสด้วยเลือดตัวเอง" },
            { url: "0jbJqnCLbuY", title: "PRP สวยปลอดภัยด้วยเซลล์เลือดตัวเอง" }
          ]
        }
      ]
    };
  },
  methods: {
    playing(col, row) {
      this.videoPlay.col = col;
      this.videoPlay.row = row;
    },
    ready(event) {
      this.player.push(event.target);
    },
    pause() {}
  }
};
</script>

<style lang="scss" scoped>
@import "@scss/default.scss";
@import "@scss/color.scss";
@import "@scss/aspect-ratio";

// header
.blogs-header {
  text-align: center;
  padding: 30px 0;

  .head {
    color: $pink1;
    font-size: 25px;
  }
}

// body
.blogs-body {
  * {
    transition: all 0.3s ease;
  }

  iframe {
    width: 100%;
    height: 35vw;

    @include small {
      height: 40vw;
    }
  }

  .row > [class*="col-"]:not(.title) {
    padding: 5px;
  }
  .blogs-item {
    border: 2px solid $pink1;
    padding: 5px;
    @include aspect-ratio(16, 9);

    .content {
      padding: 5px;
    }
  }
}
</style>
